<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-24 09:12:25
 * @LastEditors: ------
 * @LastEditTime: 2021-08-12 10:00:10
-->
<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="go('home')" class="cursor">
          首页 >
        </span>
      </template>
      <template #two>
        <span @click="go('customerList')" class="cursor">
          客户管理 >
        </span>
      </template>
      <template #three>
        <span class="cursor">
          客户本人信息
        </span>
      </template>
      <template v-slot:slotCon>
        <div class="smallBox bg_white_color">
          <div class="box_left">
            <div class="left_top">
              <img src="../image/info.png" alt="" />
              <span class="base_333_color">客户信息</span>
            </div>
            <div class="left_bot">
              <div class="text_box">
                <div>用户姓名：</div>
                <input
                  placeholder="请输入用户姓名"
                  class="userName"
                  v-model="name"
                  ref="userName"
                />
              </div>
              <div class="text_box">
                <div>用户手机号：</div>
                <input
                  placeholder="请输入用户手机号"
                  type="text"
                  class="userName"
                  v-model="number"
                  ref="Number"
                />
              </div>
              <div class="text_box">
                <div>用户身份证：</div>
                <input
                  placeholder="请输入用户身份证号"
                  class="userName"
                  v-model="id"
                  ref="id"
                />
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="box_right">
            <div class="left_top">
              <img src="../image/address.png" alt="" />
              <span class="base_333_color">居住地址</span>
            </div>
            <div class="left_bot">
              <div class="text_box">
                <div>所在城市：</div>

                <a-cascader
                  size="large"
                  :options="options"
                  @change="onChange"
                  placeholder="省/市/区"
                ></a-cascader>
              </div>
              <div class="text_box">
                <div>居住地址：</div>
                <input
                  placeholder="请输入服务对象居住地址"
                  class="userName"
                  v-model="ServeAddress"
                  ref="ServeAddress"
                />
              </div>
            </div>
          </div>
          <button class="nextPage1 cursor base_white_color" @click="nextPage">
            下一步，创建家庭成员
          </button>
          <button class="nextPage cursor base_white_color" @click="jump">
            跳过，直接保存客户信息
          </button>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn ">
      <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>

      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import city from "../assets/pca-code.json";
import { consumerInfo } from "../api/api";

export default {
  // name: "about",
  data() {
    return {
      name: "",
      number: "",
      id: "",
      options: null,
      ServeAddress: "",
      value: [],
      contract: {
        consumerFamilyMemberList: [{}],
        name: "",
        mobile: "",
        idCard: "",
        status: "10",
        province: "",
        city: "",
        county: "",
        detailAddress: "",
        street: "",
        mycity: "",
        registSource: "3",
      },
      address: "",
    };
  },
  created() {
    this.options = city;
  },
  components: { TitleNav, ebutton },
  methods: {
    onChange(value, selectedOptions) {
      const select = selectedOptions.map((item) => item.label);
      this.value = select;
      console.log(value, select);
      this.contract.province = value[0];
      this.contract.city = value[1];
      this.contract.county = value[2];
      this.address = select[0] + select[1] + select[2];
      this.contract.mycity = this.address;
    },
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "customerList":
          this.$router.push("/customerList");
          break;
        default:
          break;
      }
    },

    goContract() {
      this.$router.push("/serveManage");
    },

    jump() {
      let that = this;

      if (that.name == "") {
        //校验姓名
        that.$refs.userName.placeholder = "不能为空";
        that.$refs.userName.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.name != "") {
        that.$refs.userName.style.border = "";
      }
      if (that.number.length != 11) {
        //校验手机号
        that.$refs.Number.placeholder = "手机号码不正确";
        that.$refs.Number.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.number.length == 11) {
        // let phone = /^[1][3,4,5,7,8][0-9]{9}$/;
        // if (phone.test(that.number) == true) {
        that.$refs.Number.style.border = "";
        // } else {
        //   that.number = "";
        //   that.$refs.Number.placeholder = "手机号错误";
        //   that.$refs.Number.style.border = "1px solid  #ff9158";
        //   return false;
        // }
      }
      if (that.id == "") {
        //校验身份证
        that.$refs.id.placeholder = "不能为空";
        that.$refs.id.style.border = "1px solid #ff9158";
        return false;
      } else if (that.id != "") {
        that.$refs.id.style.border = "";
      }
      if (that.value.length == 0) {
        //校验所在城市
        that.$message.warning("请选择所在城市!");
        return false;
      }
      if (that.ServeAddress == "") {
        //校验服务对象住址
        that.$refs.ServeAddress.placeholder = "不能为空";
        that.$refs.ServeAddress.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.ServeAddress != "") {
        that.$refs.ServeAddress.style.border = "";
      }
      that.contract.name = that.name;
      that.contract.mobile = that.number;
      that.contract.idCard = that.id;
      that.contract.street = that.ServeAddress;
      that.contract.consumerFamilyMemberList = [{ name: "" }];

      that.$store.commit("setConsumer", that.contract);

      consumerInfo(that.contract).then((res) => {
        console.log(res);
        if (res.data.success) {
          that.$confirm({
            title: "保存成功!",
            content: res.data.message,
            okText: "确认",
            // cancelText: "取消",
            onOk() {
              that.$router.push("/personal");
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        } else {
          that.$error({
            title: "保存失败!",
            content: "请重新尝试!",
          });
        }
      });
    },
    nextPage() {
      let that = this;

      if (that.name == "") {
        //校验姓名
        that.$refs.userName.placeholder = "不能为空";
        that.$refs.userName.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.name != "") {
        that.$refs.userName.style.border = "";
      }
      if (that.number == "") {
        //校验手机号
        that.$refs.Number.placeholder = "不能为空";
        that.$refs.Number.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.number != "") {
        let phone = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (phone.test(that.number) == true) {
          that.$refs.Number.style.border = "";
        } else {
          that.number = "";
          that.$refs.Number.placeholder = "手机号错误";
          that.$refs.Number.style.border = "1px solid  #ff9158";
          return false;
        }
      }
      if (that.id == "") {
        //校验身份证
        that.$refs.id.placeholder = "不能为空";
        that.$refs.id.style.border = "1px solid #ff9158";
        return false;
      } else if (that.id != "") {
        that.$refs.id.style.border = "";
      }
      if (that.value.length == 0) {
        //校验所在城市
        that.$message.warning("请选择所在城市!");
        return false;
      }
      if (that.ServeAddress == "") {
        //校验服务对象住址
        that.$refs.ServeAddress.placeholder = "不能为空";
        that.$refs.ServeAddress.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.ServeAddress != "") {
        that.$refs.ServeAddress.style.border = "";
      }

      that.contract.name = that.name;
      that.contract.mobile = that.number;
      that.contract.idCard = that.id;
      that.contract.street = that.ServeAddress;
      that.contract.detailAddress = that.address + that.ServeAddress;
      // that.contract.consumerFamilyMemberList = [{}];
      that.$store.commit("setConsumer", that.contract);

      that.$router.push("/infoTwo");
    },
  },
};
</script>

<style scoped>
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
/* -------------------------------------------------------------------------- */

:deep(.ant-cascader-picker) {
  width: 372px;
  padding: 6px 0;
  height: 48px;
  font-size: 20px;
  color: #666666;
}
:deep(.ant-cascader-picker:hover input) {
  border: 1px solid var(--themeColor);
}
:deep(.ant-cascader-picker::after) {
  content: "";
  display: inline-block;
  height: 48px;
  width: 48px;
  background-color: rgba(204, 204, 204, 0.575);
  position: absolute;
  right: 0px;
  border-radius: 8px;
  margin-top: -8px;
}
:deep(.ant-cascader-picker::before) {
  position: absolute;
  content: "";
  height: 0px;
  width: 0px;
  border: 8px solid;
  border-color: #ccc transparent transparent transparent;
  right: 16px;
  top: 18px;
}
:deep(.ant-cascader-picker input) {
  height: 48px;
  margin-top: -8px;
  border-radius: 8px;
  font-size: 20px;
}
:deep(.ant-cascader-picker:focus .ant-cascader-input) {
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}

:deep(.ant-input::placeholder) {
  font-size: 20px;
  height: 48px;
  padding: 8px;
  color: #666;
}
:deep(.ant-cascader-picker-label) {
  padding: 0px 48px 0px 21px;
  font-size: 20px;
  margin-top: -12px;
}
:deep(.ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: var(--themeColor);
}
:deep(.ant-cascader-picker:focus .ant-cascader-input) {
  border-color: var(--themeColor);
}
:deep(.ant-input:focus) {
  border: 1px solid var(--themeColor);
  box-shadow: none;
}
:deep(svg) {
  display: none;
}
:deep(.anticon) {
  display: none;
}

.smallBox {
  width: 1520px;
  height: 606px;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  padding: 40px 46px;
  display: flex;
  justify-content: space-between;
}
.line {
  height: 256px;
  border-left: 1px solid #e2e2e2;
  margin-top: 125px;
}
.left_top img {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
}
.left_top span {
  font-size: 22px;
}
.left_top {
  padding-bottom: 32px;
  margin-bottom: 78px;
  width: 687px;
  border-bottom: 1px solid #e2e2e2;
}
.left_bot {
  margin-left: 50px;
  width: 553px;
  font-size: 20px;
}
.left_bot > div {
  display: flex;
  justify-content: space-between;
}
input::placeholder {
  font-size: 20px;
  color: #cacaca;
}
input {
  outline: none;
  border: none;
}

.userName {
  width: 372px;
  height: 48px;
  border-radius: 8px;
  line-height: 48px;
  padding-left: 20px;
  border: 1px solid #ccc;
}

input:focus {
  border: none;
  box-shadow: 0px 0px 3px var(--themeColor);
  border: 1px solid var(--themeColor);
}
input:hover {
  border: 1px solid var(--themeColor);
}
.text_box {
  height: 48px;
  line-height: 48px;
  margin-top: 28px;
  position: relative;
}
.nextPage {
  width: 257px;
  height: 64px;
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 10px;
  position: absolute;
  bottom: 192px;
  left: 890px;
  font-size: 20px;
}
.nextPage1 {
  width: 257px;
  height: 64px;
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
  border-radius: 10px;
  position: absolute;
  font-size: 20px;
  bottom: 192px;
  left: 580px;
}
</style>
